.headerSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
}

.selectBox {
    width: 25rem;
    margin-bottom: 2rem;
}

@media (max-width:440px) {
    .selectBox {
        width: calc(100vw - 4rem);
    }
}

.donationWrap {
    display: flex;
    gap: 2rem;
    max-width: 80rem;
    flex-wrap: wrap;
    justify-content: center;
}

.heatMap {
    width: 800px;
    height: 450px;
    border-radius: 0.5rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .07);
    padding: 1rem;
    margin-bottom: 2.5rem;
}

@media (max-width: 870px) {
    .heatMap {
        width: 87vw;
        padding: 0.75rem;
    }
}