.headerSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

.headerLogo {
    width: 30rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
}

@media (max-width: 870px) {
    .headerLogo {
        width: 70vw;
        margin-top: 0.5rem;
        margin-bottom: 2.2rem;
    }
}