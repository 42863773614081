@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    display: block;
}

.App {
    max-width: 100vw;
    overflow: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e9e8e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3771e5;
}